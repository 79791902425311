import React, { Component } from 'react';
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap';
import './App.css';
import logo from './logo_background.png';
import { Form, Text } from 'informed';
import "isomorphic-fetch";


class Surgery extends Component {
  constructor (props) {
    super(props);
    this.state = { 
      predict_result: {},
      isSubmitDisabled: false,
      formInvalid: false
    };

    this.prediction = {
      mort: true,
      development : false,
      apasl: true,
      easl : false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, value) {
    this.setState({
      [field]: value,
      predict_result: {}
    });
    console.log(this.state);
  }

  sendPost(payLoad) {
   console.log(payLoad);
   fetch("http://vocal.icu/surgery_risk/", {
    method: "POST",
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payLoad)
    }).then((response) => {
        console.log(response);
        return response.json();
    }).then((json) => {
        let prediction_response = json.body;
        console.log(prediction_response);
        this.setState({
          predict_result: prediction_response,
          isSubmitDisabled: false
        });
  	})
    .catch(function(err) {
    // Error: response error, request timeout or runtime error
    console.log(' error! ', err);
    });
  }

  processPrediction(object) {
    if (this.state.prediction === "development") { //Development
      let span_3mo = object.span_3mo;
      let span_6mo = object.span_6mo;
      let span_12mo = object.span_12mo;

      if (span_3mo > span_6mo) {
        span_6mo = span_3mo;
        object.span_6mo = span_3mo;
      }

      if (span_6mo > span_12mo) {
        span_12mo = span_6mo;
        object.span_12mo = span_6mo;
      }
    } else { //Mortality
      let span_28 = object.span_28;
      let span_90 = object.span_90;

      if (span_28 > span_90) {
        span_90 = span_28;
      }
    }

    return object;
  }

  

  convertToPercent (number) {
    if (number === undefined) {
      return '';
    }

    let rounded = number * 100;
    rounded = Math.round(rounded * 100)/100;
    return rounded.toFixed(2) + "%";
  }

  
  basicValidation = value => {
    return !value || value.length < 1 ? "Please check your form" : undefined;
  }

  handleSubmit(state) {
    this.setState({
      isSubmitDisabled: true
    });

    this.setState({
      formInvalid: false
    });

    let payLoad = {...state, ...this.state};
    let modifiedPayload = {
      age: Number(payLoad.age),
      albumin: Number(payLoad.albumin),
      bilirubin: Number(payLoad.bilirubin),
      platelet: Number(payLoad.platelet),
      bmi: payLoad.bmi,
      nafld: payLoad.nafld,
      asa: payLoad.asa,
      category: payLoad.category,
      emergency: payLoad.emergency
    }
    this.sendPost(modifiedPayload);
  }

  render() {
    return (
        <Form
        onSubmit={formState => this.handleSubmit(formState)}
        id="complex-form">
        {({ formState }) => (

        <Container >
          <br/>

          <Row>
            <Col md="2">
              <img src={logo} className="App-logo" alt="logo" />
            </Col>
            <Col md="10">
                VOCAL - Penn Cirrhosis Surgical Risk Calculator
            </Col>
            {/* <Col md="2" className="Prediction-bar">
              <Button className="buttonLink" onClick={() => this.handleChange('showLiterature', true)}>Literature</Button>
            </Col> */}
          </Row>
        <br />
        
        {/* Main calculator div */}
        <div>
        <p className="header_title">
        Enter the following data:
        </p>
        <br/>
        <Row>
        <Col md="6" >
            <Col className="scroll">
              <Row>
                <Col md="4">
                  <label htmlFor="age">Age:</label>
                </Col>
                <Col md="8">
                  <Text field="age" id="age" placeholder="years" validate={this.basicValidation} />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <label htmlFor="albumin">Albumin:</label>
                </Col>
                <Col md="8">
                  <Text field="albumin" id="albumin" placeholder="g/dL" validate={this.basicValidation} />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <label htmlFor="bilirubin">Total Bilirubin:</label>
                </Col>
                <Col md="8">
                  <Text field="bilirubin" id="bilirubin" placeholder="mg/dL" validate={this.basicValidation}/>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <label htmlFor="platelet">Platelet Count:</label>
                </Col>
                <Col md="8">
                  <Text field="platelet" id="platelet" placeholder="x1,000/µL" validate={this.basicValidation} />
                </Col>
              </Row>

              <div>
              <Row>
                <Col md="6">
                  BMI ≥30:
                </Col>
                <Col md="6">
                  <ButtonGroup type="number" field="bmi">
                    <Button outline color="primary" value="0" onClick={() => this.handleChange('bmi', 0)}
                      active={this.state.bmi === 0}
                    >No</Button>
                    <Button outline color="primary" value="1" onClick={() => this.handleChange('bmi', 1)}
                      active={this.state.bmi === 1}
                    >Yes</Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  NAFLD:
                </Col>
                <Col md="6">
                  <ButtonGroup type="number" field="nafld">
                    <Button outline color="primary" value="0" onClick={() => this.handleChange('nafld', 0)}
                      active={this.state.nafld === 0}
                    >No</Button>
                    <Button outline color="primary" value="1" onClick={() => this.handleChange('nafld', 1)}
                      active={this.state.nafld === 1}
                    >Yes</Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                 ASA Score:
                </Col>
                <Col md="6">
                  <ButtonGroup type="number" field="asa">
                    <Button outline color="primary" value="2" onClick={() => this.handleChange('asa', 2)}
                      active={this.state.asa === 2}
                    >2</Button>
                    <Button outline color="primary" value="3" onClick={() => this.handleChange('asa', 3)}
                      active={this.state.asa === 3}
                    >3</Button>
                    <Button outline color="primary" value="4" onClick={() => this.handleChange('asa', 4)}
                      active={this.state.asa === 4}
                    >4</Button>
                  </ButtonGroup>
                </Col>
              </Row>
              </div>

              <br/>
              <Row>
                <Col md="6">
                 Surgery Category:
                </Col>

               <Col md="6">
                  <ButtonGroup size="sm" vertical type="number" field="category">
                    <Button outline color="primary" onClick={() => this.handleChange('category', '')}
                      active={this.state.category === ''}
                    >Abdominal - Lap</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('category', 'Abdominal')}
                      active={this.state.category === 'Abdominal'}
                    >Abdominal - Open</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('category', 'Adbdominal Wall')}
                      active={this.state.category === 'Adbdominal Wall'}
                    >Abdominal Wall</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('category', 'Vascular')}
                      active={this.state.category === 'Vascular'}
                    >Vascular</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('category', 'Major Orthopedic')}
                      active={this.state.category === 'Major Orthopedic'}
                    >Major Orthopedic</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('category', 'Chest/Cardiac')}
                      active={this.state.category === 'Chest/Cardiac'}
                    >Chest/Cardiac</Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <br />

              <Row>
                <Col md="6">
                 Emergency:
                </Col>
                <Col md="6">
                  <ButtonGroup type="number" field="emergency">
                    <Button outline color="primary" value="0" onClick={() => this.handleChange('emergency', 0)}
                      active={this.state.emergency === 0}
                    >No</Button>
                    <Button outline color="primary" value="1" onClick={() => this.handleChange('emergency', 1)}
                      active={this.state.emergency === 1}
                    >Yes</Button>
                  </ButtonGroup>
                </Col>
              </Row>
        <br />

            </Col>
        </Col>

        <Col md="5">
          <p>
             This risk calculator was derived in a large cohort of patients with cirrhosis who underwent a broad array of surgical procedures. The tool predicts post-operative mortality for patients with cirrhosis. 
             It incorporates the type of surgery under consideration, and utilizes important and readily available clinical data. Predictions may be used to risk-stratify patients for surgery, and may serve as a useful adjunct in informing the decision to pursue surgical or non-surgical management pathways.
          </p>
          <br />
          <Col className="pred_box">
            <Row className="pred_row">30-day Mortality Probability: {this.convertToPercent(this.state.predict_result.span_30)}</Row>
            <br />
            <Row className="pred_row">90-day Mortality Probability: {this.convertToPercent(this.state.predict_result.span_90)}</Row>
            <br />
            <Row className="pred_row">180-day Mortality Probability: {this.convertToPercent(this.state.predict_result.span_180)}</Row>
          </Col>
        </Col>
        </Row>

        <Col md={{offset: 11 }}>
          <Button color="primary" type="submit" disabled={this.state.isSubmitDisabled}>Calculate</Button>
        </Col>
        <pre>
            {formState.invalid || this.state.formInvalid ? "Please check that all required form fields are non-empty and/or selected": undefined}
        </pre>
        </div>
        </Container>
        )}
        </Form>
    );
  }

}

export default Surgery;
