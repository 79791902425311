import React, { Component } from 'react';
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap';
import './App.css';
import logo from './logo_background.png';
import { Form, Text } from 'informed';
import "isomorphic-fetch";


class App extends Component {
  constructor (props) {
    super(props);
    this.state = { 
      definition: "easl", 
      prediction: "development", 
      predict_result: {},
      isSubmitDisabled: false,
      formInvalid: false
    };

    this.prediction = {
      mort: true,
      development : false,
      apasl: true,
      easl : false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, value) {
    // Clears prediction results on change of prediction or definition type
    if (field === "definition" || field === "prediction") {
      console.log('here');
      this.setState({
        [field]: {},
        showLiterature: false
      });
    } else if (field === "showLiterature") {
      this.setState({
         prediction : ""
      });
    }

    console.log('here');

    this.setState({
      [field]: value,
      predict_result: {}
    });
  }

  sendPost(resultArr) {
   let definition = this.prediction.apasl ? "apasl" : "easl";
   let prediction = this.prediction.mort ? "mortality" : "development";
   let array_spec = {};

   if (this.prediction.mort) {
     array_spec =
     {
       span_90 : resultArr[0],
       span_28 : resultArr[1]
     };
   } else {
     array_spec =
     {
       span_3mo : resultArr[0],
       span_6mo : resultArr[1],
       span_12mo: resultArr[2]
     };
   }

   fetch("http://vocal.icu/aclf/", {
    method: "POST",
    headers: {
    	Accept: 'application/json',
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
          definition : definition,
          prediction: prediction,
          array_spec: array_spec
        },
    )

    }).then((response) => {
        return response.json();
    }).then((json) => {
        let prediction_response = json.body;
        prediction_response = this.processPrediction(prediction_response);

        this.setState({
          predict_result: prediction_response,
          isSubmitDisabled: false
        });
  	})
    .catch(function(err) {
    // Error: response error, request timeout or runtime error
    console.log(' error! ', err);
    });
  }

  processPrediction(object) {
    if (this.state.prediction === "development") { //Development
      let span_3mo = object.span_3mo;
      let span_6mo = object.span_6mo;
      let span_12mo = object.span_12mo;

      if (span_3mo > span_6mo) {
        span_6mo = span_3mo;
        object.span_6mo = span_3mo;
      }

      if (span_6mo > span_12mo) {
        span_12mo = span_6mo;
        object.span_12mo = span_6mo;
      }
    } else { //Mortality
      let span_28 = object.span_28;
      let span_90 = object.span_90;

      if (span_28 > span_90) {
        span_90 = span_28;
      }
    }

    return object;
  }

  splineCalc(knots, field, type) {
    var spline1;
    var originalArray = [];
    originalArray[0] = field;

    spline1 = originalArray[0];

    if (type === "cubic") { // Restricted cubic splines
      if (knots.length === 5) {
        let k1 = knots[0];
        let k2 = knots[1];
        let k3 = knots[2];
        let k4 = knots[3];
        let k5 = knots[4];

        originalArray[1] = (((spline1 - k1)**3) * (spline1 > k1 ? 1:0) - ((k5-k4)**-1)
        * (((spline1 - k4)**3) * (spline1 > k4 ? 1:0) * (k5 - k1) - ((spline1 - k5)**3) * (spline1 > k5 ? 1:0) * (k4 - k1)))
        / (k5-k1)**2;

        originalArray[2] = ((spline1 - k2)**3 * (spline1 > k2 ? 1:0) - (k5-k4)**-1
        * ((spline1 - k4)**3 * (spline1 > k4 ? 1:0) * (k5 - k2) - (spline1 - k5)**3 * (spline1 > k5 ? 1:0) * (k4 - k2)))
        / (k5-k1)**2;

        originalArray[3] = ((spline1 - k3)**3 * (spline1 > k3 ? 1:0) - (k5-k4)**-1
        * ((spline1 - k4)**3 * (spline1 > k4 ? 1:0) * (k5 - k3) - (spline1 - k5)**3 * (spline1 > k5 ? 1:0) * (k4 - k3)))
        / (k5-k1)**2;
      } else if (knots.length === 4) { // 4 knot
        let k1 = knots[0];
        let k2 = knots[1];
        let k3 = knots[2];
        let k4 = knots[3];

        originalArray[1] = (((spline1 - k1)**3) * (spline1 > k1 ? 1:0) - ((k4-k3)**-1)
        * (((spline1 - k3)**3) * (spline1 > k3 ? 1:0) * (k4 - k1) - ((spline1 - k4)**3) * (spline1 > k4 ? 1:0) * (k3 - k1)))
        / (k4-k1)**2;

        originalArray[2] = ((spline1 - k2)**3 * (spline1 > k2 ? 1:0) - (k4-k3)**-1
        * ((spline1 - k3)**3 * (spline1 > k3 ? 1:0) * (k4 - k2) - (spline1 - k4)**3 * (spline1 > k4 ? 1:0) * (k3 - k2)))
        / (k4-k1)**2;

      } else if (knots.length === 3) { // 3 knot
        let k1 = knots[0];
        let k2 = knots[1];
        let k3 = knots[2];

        originalArray[1] = ((spline1 - k1)**3 * (spline1 > k1 ? 1:0) - (k3 - k2)**-1
        * ((spline1 - k2)**3 * (spline1 > k2 ? 1:0) * (k3 - k1) - (spline1 - k3)**3 * (spline1 > k3 ? 1:0) * (k2 - k1)))
        /(k3 - k1)**2;
      }
    } else if (type === "linear") { // Linear splines
      if (knots.length === 3) {  // 3 knot
        let k1 = knots[0];
        let k2 = knots[1];
        let k3 = knots[2];

        originalArray[0] = Math.min(spline1, k1);
        originalArray[1] = Math.max(Math.min(spline1, k2), k1) - k1;
        originalArray[2] = Math.max(spline1, k2) - k2;
        originalArray[3] = Math.max(spline1, k3) - k3;
      } else if (knots.length === 2) { // 2 knot
        let k1 = knots[0];
        let k2 = knots[1];

        originalArray[0] = Math.min(spline1, k1);
        originalArray[1] = Math.max(Math.min(spline1, k2), k1) - k1;
        originalArray[2] = Math.max(spline1, k2) - k2;
      } else if(knots.length === 1) { // 1 knot
        let k1 = knots[0];

        originalArray[0] = Math.min(spline1, k1);
        originalArray[1] = Math.max(spline1, k1) - k1;
      }
    }

    return originalArray;
  }

  getLiverDX(etiology) {
    var arr = [];
    switch(etiology) {
      case "hepc":
        arr = [0,0,0,0];
        break;
      case "ald":
        arr = [0,1,0,0];
        break;
      case "hepb":
        arr = [1,0,0,0];
        break;
      case "fld":
        arr = [0,0,1,0];
        break;
      default:
        arr = [0,0,0,1];
        break;
    }
    return arr;
  }

  getEtiology(etiology) {
    var arr = [];
    switch(etiology) {
      case "hepc_non_vir":
        arr = [0,0,0,0,0,0,0];
        break;
      case "hepc_vir":
        arr = [1,0,0,0,0,0,0];
        break;
      case "ald":
        arr = [0,0,1,0,0,0,0];
        break;
      case "hepb":
        arr = [0,1,0,0,0,0,0];
        break;
      case "fld":
        arr = [0,0,0,0,0,1,0];
        break;
      case "hepc_ald_non_vir":
        arr = [0,0,0,1,0,0,0];
        break;
      case "hepc_ald_vir":
        arr = [0,0,0,0,1,0,0];
        break;
      default:
        arr = [0,0,0,0,0,0,1];
        break;
    }
    return arr;
  }


  // Replace -0 with 0 helper method
  replaceZero (arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === -0) {
        arr[i] = 0;
      }
    }
    return arr;
  }

  convertToPercent (number) {
    if (number === undefined) {
      return '';
    }

    let rounded = number * 100;
    rounded = Math.round(rounded * 100)/100;
    return rounded.toFixed(2) + "%";
  }

  processSplines(prediction, state) {
    var arr = [];
    var multiArr = [];

    if (prediction.mort && prediction.apasl) { // APASL mortality
      let age = state.age > 85 ? 85 : Number(state.age);
      let hemoglobin = Number(state.hemoglobin);
      let inr = state.inr > 20 ? 20 : Number(state.inr);
      let creatinine = state.creatinine > 8 ? 8 : Number(state.creatinine);
      let bilirubin = Number(state.bilirubin);
      let sodium = Number(state.sodium);
      let wbc = state.wbc > 30 ? 30 : Number(state.wbc);

      if (state.hemoglobin > 15) {
        hemoglobin = 15;
      } else if (state.hemoglobin < 6.5) {
        hemoglobin = 6.5;
      } else {
        hemoglobin = Number(hemoglobin);
      }

      //90 day
      arr[0] = 1; //default coefficient
      arr = arr.concat(this.splineCalc([45, 55, 60, 67], age, "cubic"));
      arr = arr.concat(this.splineCalc([8.8, 12.3, 13.3], hemoglobin, "cubic"));
      arr = arr.concat(this.splineCalc([2, 8, 20], bilirubin, "cubic"));
      arr = arr.concat(this.splineCalc([0.6, 1.0, 3.4], creatinine, "cubic"));
      arr = arr.concat(this.splineCalc([1.3, 2.0, 2.7], inr, "cubic"));
      arr = arr.concat(this.splineCalc([133, 137, 145], sodium, "cubic"));
      arr = arr.concat(this.splineCalc([], wbc, null));
      arr = arr.concat(this.state.hep_carcinoma);
      arr = arr.concat(this.getLiverDX(this.state.etiology));
      arr = this.replaceZero(arr);
      multiArr[0] = arr;

      //28 day
      let arr2 = [];
      arr2[0] = 1;
      arr2 = arr2.concat(this.state.hep_carcinoma);
      arr2 = arr2.concat(this.splineCalc([45, 55, 60, 67], age, "cubic"));
      arr2 = arr2.concat(this.splineCalc([], wbc, null));
      arr2 = arr2.concat(this.splineCalc([133, 137, 145], sodium, "cubic"));
      arr2 = arr2.concat(this.splineCalc([1.3, 2.0, 2.7], inr, "cubic"));
      arr2 = arr2.concat(this.splineCalc([2, 8, 20], bilirubin, "cubic"));
      arr2 = arr2.concat(this.splineCalc([0.6, 1.0, 3.4], creatinine, "cubic"));
      arr2 = arr2.concat(this.splineCalc([8.8, 12.3, 13.3], hemoglobin, "cubic"));
      arr2 = this.replaceZero(arr2);
      multiArr[1] = arr2;
    } else if (prediction.mort && prediction.easl){ //EASL mortality
      let age = Number(state.age);
      let albumin = Number(state.albumin);
      let inr = Number(state.inr);
      let creatinine = Number(state.creatinine);
      let bilirubin = Number(state.bilirubin);
      let sodium = Number(state.sodium);
      let wbc = Number(state.wbc);

      // 90 day
      arr[0] = 1; //default coefficient
      arr = arr.concat(this.splineCalc([51, 59, 68], age, "cubic"));
      arr = arr.concat(this.splineCalc([127, 135, 143], sodium, "cubic"));
      arr = arr.concat(this.splineCalc([1, 3, 10 , 15], wbc, "cubic"));
      arr = arr.concat(this.splineCalc([0.8, 2.8, 15.5], bilirubin, "cubic"));
      arr = arr.concat(this.splineCalc([1.7, 2.5, 3.5], albumin, "cubic"));
      arr = arr.concat(this.splineCalc([1.18, 1.6, 3.07], inr, "cubic"));
      arr = arr.concat(this.splineCalc([0.77, 1.61, 2.21, 4.2], creatinine, "cubic"));
      arr = arr.concat(this.getLiverDX(this.state.etiology));
      arr = this.replaceZero(arr);
      multiArr[0] = arr;

      // 28 day
      let arr2 = [];
      arr2[0] = 1;
      arr2 = arr2.concat(this.splineCalc([51, 59, 68], age, "cubic"));
      arr2 = arr2.concat(this.splineCalc([127, 135, 143], sodium, "cubic"));
      arr2 = arr2.concat(this.splineCalc([1, 3, 10 , 15], wbc, "cubic"));
      arr2 = arr2.concat(this.splineCalc([1.7, 2.5, 3.5], albumin, "cubic"));
      arr2 = arr2.concat(this.splineCalc([0.77, 1.61, 2.21, 4.2], creatinine, "cubic"));
      arr2 = arr2.concat(this.splineCalc([1.18, 1.6, 3.07], inr, "cubic"));
      arr2 = arr2.concat(this.splineCalc([0.8, 2.8, 15.5], bilirubin, "cubic"));
      arr2 = arr2.concat(this.getLiverDX(this.state.etiology));
      arr2 = this.replaceZero(arr2);
      multiArr[1] = arr2;
    } else if (prediction.development && prediction.apasl) { // APASL development
      let age = state.age > 85 ? 85 : Number(state.age);
      let albumin = Number(state.albumin);
      let hemoglobin = state.hemoglobin > 18 ? 18 : Number(state.hemoglobin);
      let inr = Number(state.inr);
      let creatinine = state.creatinine > 2 ? 2 : Number(state.creatinine);
      let bilirubin = Number(state.bilirubin);
      let platelets = state.platelets > 500 ? 500 : Number(state.platelets);

      if (state.inr > 5) {
        inr = 5;
      } else if (state.inr < .5) {
        inr = 0.5;
      } else {
        inr = Number(inr);
      }

      // 3 month
      arr[0] = 1; //default coefficient
      arr = arr.concat(this.splineCalc([2.8, 3.7, 4.4], albumin, "cubic"));
      arr = arr.concat(this.splineCalc([12.2, 15.7], hemoglobin, "linear"));
      arr = arr.concat(this.splineCalc([0.7, 0.9, 1.4], creatinine, "cubic"));
      arr = arr.concat(this.splineCalc([2.5, 7], bilirubin, "linear"));
      arr = arr.concat(this.splineCalc([1.7], inr, "linear"));
      arr = arr.concat(this.state.hep_carcinoma);
      arr = this.replaceZero(arr);
      multiArr[0] = arr;

      // 6 month
      let arr2 = [];
      arr2[0] = 1;
      arr2 = arr2.concat(this.splineCalc([0.7, 0.9, 1.4], creatinine, "cubic"));
      arr2 = arr2.concat(this.state.auditc);
      arr2 = arr2.concat(this.splineCalc([54, 111, 166, 291], platelets, "cubic"));
      arr2 = arr2.concat(this.splineCalc([1.7], inr, "linear"));
      arr2 = arr2.concat(this.splineCalc([2.5, 7], bilirubin, "linear"));
      arr2 = arr2.concat(this.state.hep_carcinoma);
      arr2 = arr2.concat(this.splineCalc([2.8, 3.7, 4.4], albumin, "cubic"));
      arr2 = arr2.concat(this.getEtiology(this.state.etiology));
      arr2 = this.replaceZero(arr2);
      multiArr[1] = arr2;

      // 12 month
      let arr3 = [];
      arr3[0] = 1;
      arr3 = arr3.concat(this.splineCalc([38, 52, 62, 68], age, "cubic"));
      arr3 = arr3.concat(this.splineCalc([2.8, 3.7, 4.4], albumin, "cubic"));
      arr3 = arr3.concat(this.state.auditc);
      arr3 = arr3.concat(this.splineCalc([54, 111, 166, 291], platelets, "cubic"));
      arr3 = arr3.concat(this.state.hep_carcinoma);
      arr3 = arr3.concat(this.splineCalc([12.2, 15.7], hemoglobin, "linear"));
      arr3 = arr3.concat(this.splineCalc([1.7], inr, "linear"));
      arr3 = arr3.concat(this.splineCalc([2.5, 7], bilirubin, "linear"));
      arr3 = arr3.concat(this.splineCalc([0.7, 0.9, 1.4], creatinine, "cubic"));
      arr3 = arr3.concat(this.getEtiology(this.state.etiology));
      arr3 = this.replaceZero(arr3);
      multiArr[2] = arr3;
    } else if (prediction.development && prediction.easl) { // EASL Development
      let sodium = Number(state.sodium);
      let bilirubin = Number(state.bilirubin);
      let inr = Number(state.inr);
      let creatinine = Number(state.creatinine);
      let albumin = Number(state.albumin);
      let platelets = Number(state.platelets);
      let hemoglobin = Number(state.hemoglobin);

      // 3 month
      arr[0] = 1; //default coefficient
      arr = arr.concat(this.state.auditc);
      arr = arr.concat(this.state.dm);
      arr = arr.concat(this.splineCalc([1, 1.11, 1.5], inr, "cubic"));
      arr = arr.concat(this.splineCalc([0.4, 0.8, 2.2], bilirubin, "cubic"));
      arr = arr.concat(this.splineCalc([134, 138, 142], sodium, "cubic"));
      arr = arr.concat(this.splineCalc([0.68, 0.9, 1.2], creatinine, "cubic"));
      arr = arr.concat(this.splineCalc([2.7, 3.7, 4.37], albumin, "cubic"));
      arr = arr.concat(this.splineCalc([10.7, 13.7, 15.9], hemoglobin, "cubic"));
      arr = this.replaceZero(arr);
      multiArr[0] = arr;

      // 6 month
      let arr2 = [];
      arr2[0] = 1;
      arr2 = arr2.concat(this.state.auditc);
      arr2 = arr2.concat(this.state.dm);
      arr2 = arr2.concat(this.splineCalc([1, 1.11, 1.5], inr, "cubic"));
      arr2 = arr2.concat(this.splineCalc([0.4, 0.8, 2.2], bilirubin, "cubic"));
      arr2 = arr2.concat(this.splineCalc([134, 138, 142], sodium, "cubic"));
      arr2 = arr2.concat(this.splineCalc([0.68, 0.9, 1.2], creatinine, "cubic"));
      arr2 = arr2.concat(this.splineCalc([2.7, 3.7, 4.37], albumin, "cubic"));
      arr2 = arr2.concat(this.splineCalc([64, 133, 246], platelets, "cubic"));
      arr2 = arr2.concat(this.splineCalc([10.7, 13.7, 15.9], hemoglobin, "cubic"));
      arr2 = arr2.concat(this.getLiverDX(this.state.etiology));
      arr2 = this.replaceZero(arr2);
      multiArr[1] = arr2;

      // 12 month
      let arr3 = [];
      arr3[0] = 1;
      arr3 = arr3.concat(this.state.dm);
      arr3 = arr3.concat(this.splineCalc([1, 1.11, 1.5], inr, "cubic"));
      arr3 = arr3.concat(this.splineCalc([0.4, 0.8, 2.2], bilirubin, "cubic"));
      arr3 = arr3.concat(this.splineCalc([10.7, 13.7, 15.9], hemoglobin, "cubic"));
      arr3 = arr3.concat(this.splineCalc([0.68, 0.9, 1.2], creatinine, "cubic"));
      arr3 = arr3.concat(this.splineCalc([2.7, 3.7, 4.37], albumin, "cubic"));
      arr3 = this.replaceZero(arr3);
      multiArr[2] = arr3;
    }

    return multiArr;
  }

  basicValidation = value => {
    return !value || value.length < 1 ? "Please check your form" : undefined;
  }

  handleSubmit(state) {
    this.setState({
      isSubmitDisabled: true
    });
    var resultArr = [];

    this.prediction.apasl = this.state.definition === "apasl" ? true : false;
    this.prediction.easl = this.state.definition === "easl" ? true : false;
    this.prediction.mort = this.state.prediction === "mortality" ? true : false;
    this.prediction.development = this.state.prediction === "development" ? true : false;
    resultArr = this.processSplines(this.prediction, state);
    console.log(resultArr);
    
    for (var array of resultArr) { 
      for(var item of array) {
        if (item === undefined) { 
          this.setState({
            isSubmitDisabled: false,
            formInvalid: true
          });
          return;
        }
      }
    }

    this.setState({
      formInvalid: false
    });

    this.sendPost(resultArr);
  }

  render() {
    return (
        <Form
        onSubmit={formState => this.handleSubmit(formState)}
        id="complex-form">
        {({ formState }) => (

        <Container >
          <br/>

          <Row>
            {/* <Col md={{offset:1}}> */}
            <Col md="2">
              <img src={logo} className="App-logo" alt="logo" />
            </Col>
            <Col md="2" className="Prediction-bar">
                I want to predict:
            </Col>
            <Col md="6" className="Prediction-bar">
              <ButtonGroup size="md">
                <Button outline color="primary" onClick={() => this.handleChange('prediction', 'development')}
                  active={this.state.prediction === 'development'}>ACLF Development
                </Button>
                <Button outline color="primary" onClick={() => this.handleChange('prediction', 'mortality')}
                  active={this.state.prediction === 'mortality'}>ACLF Mortality
                </Button>
              </ButtonGroup>
            </Col>
            <Col md="2" className="Prediction-bar">
              <Button className="buttonLink" onClick={() => this.handleChange('showLiterature', true)}>Literature</Button>
            </Col>
          </Row>

          <Col md={{offset: 3 }}>
            <Row>
             {/* TODO: Add back buttongroup for prediction */}
            </Row>

            {/* <Row>
              <Col md="3">
                Using the defintion:
              </Col>
              <Col md="9">
                <ButtonGroup size="md">
                   <Button outline color="primary" onClick={() => this.handleChange('definition', 'apasl')}
                    active={this.state.definition === 'apasl'}>Asian (APASL)
                  </Button>
                  <Button outline color="primary" onClick={() => this.handleChange('definition', 'easl')}
                    active={this.state.definition === 'easl'}>European (EASL)
                  </Button>
                </ButtonGroup>
              </Col>
            </Row> */}
          </Col>

        <br />
        
        <div style={this.state.showLiterature ? {display: 'block', height: '500px'} : {display: 'none'}}>
          <p>The ACLFcalc risk prediction models were developed using modern inferential methods in a longitudinal 
            cohort of >129,000 patients with cirrhosis in the Veterans Health Administration (called the VOCAL cohort).</p>
            
          <p>Read the seminal article detailing the creation of the EASL ACLF risk prediction models: <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/liv.14328">Risk prediction scores for acute on chronic liver failure development and mortality</a>
          </p>

          <p>Other ACLF-related publications by the VOCAL group: <a href="https://aasldpubs.onlinelibrary.wiley.com/doi/10.1002/hep.30494">Incidence and Mortality of Acute‐on‐Chronic Liver Failure Using Two Definitions in Patients with Compensated Cirrhosis</a>
          <br></br>
          <a href="https://aasldpubs.onlinelibrary.wiley.com/doi/10.1002/hep.31012">Grade 1 Acute on Chronic Liver Failure Is a Predictor for Subsequent Grade 3 Failure</a>
          </p>
        </div>

        {/* Main calculator div */}
        <div style={this.state.prediction? {display: 'block'} : {display: 'none'} }>
        <p className="header_title">
        Enter the following data:
        </p>
        <br/>
        <Row>
        <Col md="6" >
            <Col className="scroll">
              {this.state.definition === "easl" && this.state.prediction === "mortality" ?
              <Row>
                <Col md="4">
                  <label htmlFor="age">Age:</label>
                </Col>
                <Col md="8">
                  <Text field="age" id="age" placeholder="years" validate={this.basicValidation} />
                </Col>
              </Row>
              : null}

              {this.state.definition === "easl" ?
              <Row>
                <Col md="4">
                  <label htmlFor="albumin">Albumin:</label>
                </Col>
                <Col md="8">
                  <Text field="albumin" id="albumin" placeholder="g/dL" validate={this.basicValidation} />
                </Col>
              </Row>
              : null}

              <Row>
                <Col md="4">
                  <label htmlFor="inr">INR:</label>
                </Col>
                <Col md="8">
                  <Text field="inr" id="inr" validate={this.basicValidation} />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <label htmlFor="creatinine">Creatinine:</label>
                </Col>
                <Col md="8">
                  <Text field="creatinine" id="creatinine" placeholder="mg/dL" validate={this.basicValidation} />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <label htmlFor="bilirubin">Total Bilirubin:</label>
                </Col>
                <Col md="8">
                  <Text field="bilirubin" id="bilirubin" placeholder="mg/dL" validate={this.basicValidation}/>
                </Col>
              </Row>

              {this.state.definition === "easl" && this.state.prediction === "development" ?
              <Row>
                <Col md="4">
                  <label htmlFor="hemoglobin">Hemoglobin:</label>
                </Col>
                <Col md="8">
                  <Text field="hemoglobin" id="hemoglobin" placeholder="g/dL" validate={this.basicValidation} />
                </Col>
              </Row>
              : null}


              {this.state.prediction === "development" ?
              <Row>
                <Col md="4">
                  <label htmlFor="platelets">Platelets:</label>
                </Col>
                <Col md="8">
                  <Text field="platelets" id="platelets" placeholder="x1,000/µL" validate={this.basicValidation} />
                </Col>
              </Row>
              : null}

              {this.state.prediction === "mortality" ||
               (this.state.prediction === "development" && this.state.definition === "easl") ?
              <Row>
                <Col md="4">
                  <label htmlFor="sodium">Sodium:</label>
                </Col>
                <Col md="8">
                  <Text field="sodium" id="sodium" placeholder="mmoL/L" validate={this.basicValidation}/>
                </Col>
              </Row>
              : null}

              {this.state.prediction === "mortality" ?
              <Row>
                <Col md="4">
                  <label htmlFor="wbc">WBC Count:</label>
                </Col>
                <Col md="8">
                  <Text field="wbc" id="wbc" placeholder="x1,000/µL" validate={this.basicValidation}/>
                </Col>
              </Row>
              : null}
              <br />
              
              <Row>
                <Col md="6">
                  Etiology of liver disease:
                </Col>

               <Col md="6">
                  <ButtonGroup size="sm" vertical type="number" field="etiology">
                    <Button outline color="primary" onClick={() => this.handleChange('etiology', 'hepc')}
                      active={this.state.etiology === 'hepc'}
                    >Hepatitis C</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('etiology', 'ald')}
                      active={this.state.etiology === 'ald'}
                    >Alcoholic liver disease</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('etiology', 'hepb')}
                      active={this.state.etiology === 'hepb'}
                    >Hepatitis B</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('etiology', 'fld')}
                      active={this.state.etiology === 'fld'}
                    >Fatty liver disease</Button>

                    <Button outline color="primary" onClick={() => this.handleChange('etiology', 'Other')}
                      active={this.state.etiology === 'Other'}
                    >Other</Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <br />

              {this.state.definition === "easl" && this.state.prediction === "development" ?
              <div>
              <Row>
                <Col md="6">
                  Diabetes mellitus:
                </Col>
                <Col md="6">
                  <ButtonGroup type="number" field="dm">
                    <Button outline color="primary" value="0" onClick={() => this.handleChange('dm', 0)}
                      active={this.state.dm === 0}
                    >No</Button>
                    <Button outline color="primary" value="1" onClick={() => this.handleChange('dm', 1)}
                      active={this.state.dm === 1}
                    >Yes</Button>
                  </ButtonGroup>
                </Col>
              </Row>
              </div>
              : null}

        {this.state.prediction === "development" ?
          <Row>
            <Col md="6">
              Active alcohol use:
            </Col>
            <Col md="6">
              <ButtonGroup type="number" field="auditc">
                <Button outline color="primary" value="0" onClick={() => this.handleChange('auditc', 0)}
                  active={this.state.auditc === 0}
                >No</Button>
                <Button outline color="primary" value="1" onClick={() => this.handleChange('auditc', 1)}
                  active={this.state.auditc === 1}
                >Yes</Button>
              </ButtonGroup>
            </Col>
          </Row>
          : null}
        <br />

            </Col>
        </Col>

        <Col md="5">
        {this.state.prediction === "mortality"?
          <p>
            This calculator provides short-term mortality predictions for patients diagnosed with acute on 
            chronic liver failure (ACLF) as defined by the European Association for the Study of Liver Diseases (EASL). 
            Predictions may be used to assist in decision making to triage patients to a higher level of care, prompt 
            expedited liver transplant evaluation, or prioritize palliation.
          </p>
          :
          <p>
            This calculator projects the predicted probability of 
            developing acute on chronic liver failure (ACLF) at timepoints in the next year, 
            as defined by the European Association for the Study of Liver Diseases (EASL). 
            Predictions may be used in the outpatient setting to risk-stratify patients for ACLF, 
            and may be useful to establish follow-up intervals, prompt outpatient liver transplant evaluation, 
            and/or provide prognostic information.
          </p>
          }
          <br />
          {this.state.prediction === "mortality"?
          <Col className="pred_box">
            <Row className="pred_row">28-day Mortality Probability: {this.convertToPercent(this.state.predict_result.span_28)}</Row>
            <br />
            <Row className="pred_row">90-day Mortality Probability: {this.convertToPercent(this.state.predict_result.span_90)}</Row>
          </Col>
          :
          <Col className="pred_box">
            <Row className="pred_row">3-month ACLF Probability: {this.convertToPercent(this.state.predict_result.span_3mo)}</Row>
            <br />
            <Row className="pred_row">6-month ACLF Probability: {this.convertToPercent(this.state.predict_result.span_6mo)}</Row>
            <br />
            <Row className="pred_row">12-month ACLF Probability: {this.convertToPercent(this.state.predict_result.span_12mo)}</Row>
          </Col>
          }
        </Col>
        </Row>

        <Col md={{offset: 11 }}>
          <Button color="primary" type="submit" disabled={this.state.isSubmitDisabled}>Calculate</Button>
        </Col>
        <pre>
            {formState.invalid || this.state.formInvalid ? "Please check that all required form fields are non-empty and/or selected": undefined}
        </pre>
        </div>
        </Container>
        )}
        </Form>
    );
  }

}

export default App;
